import React from "react";
import "../../style/Home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  return (
    <div className="home__wrapper">
      <div className="home__intro">
        <h4>AI SOCIAL LISTENER</h4>
        <h1>Elevate Your Content and Reach More People</h1>
        <p>
          Let our GenAI tool analyze your content and trends to provide
          personalized recommendations and drive better results. Choose how
          Feelim can help you supercharge your content.
        </p>
      </div>
      <div className="home__content">
        <a className="home__navigation" href="/post-analysis">
          Check Before Publishing
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            className="app-icon-space"
          />
        </a>
        <a className="home__navigation" href="/linkedin-post-analysis">
          Review My LinkedIn Post
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            className="app-icon-space"
          />
        </a>
        <a className="home__navigation" href="/trends">
          Discover Trending Topics
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            className="app-icon-space"
          />
        </a>
      </div>
    </div>
  );
};

export default Home;
