// src/utils/apiUtils.js
import axios from "axios";

// Utility function for making recommendations API call
export const getHeatmap = async (image, uri) => {
  // Prepare the request data
  const data = JSON.stringify({
    imageContent: image,
  });

  // Set up the request configuration
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: uri, // Pass the URI as a parameter
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    // Make the API request
    const response = await axios.request(config);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching recommendations:", error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};
