import React, { useRef } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "../../style/AccordionItem.scss";
const AccordionItem = ({
  ageRange,
  author,
  industries,
  keyword,
  post,
  trend,
  urlPost,
  isOpen,
  onClick,
}) => {
  const contentHeight = useRef();
  return (
    <div className="accordion__wrapper">
      <button
        className={`question-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        {trend}
        <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} />
      </button>

      <div
        ref={contentHeight}
        className="answer-container"
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight + 40 }
            : { height: "0px" }
        }
      >
        <div className="trends__content">
          <div className="trends__content-item trends__content-item--reverse">
            <div className="trends__items-row">
              <label className="trends__label">Industries</label>
            </div>
            <div className="trends__items-row">
              {industries.split(",").map((industry, index) => {
                return (
                  <label key={index} className="trends__items-label">
                    {industry}
                  </label>
                );
              })}
            </div>
          </div>
          <div className="trends__content-item trends__content-item--reverse">
            <div className="trends__items-row">
              <label className="trends__label">Keywords</label>
            </div>
            <div className="trends__items-row">
              {keyword.split(",").map((item, index) => {
                return (
                  <label key={index} className="trends__items-label">
                    {item}
                  </label>
                );
              })}
            </div>
          </div>

          <div className="trends__content-item">
            <div className="trends__items-row">
              <label className="trends__label">Author</label>
            </div>
            <div className="trends__items-row">
              <label className="trends__items-label">{author}</label>
            </div>
          </div>

          <div className="trends__content-item">
            <div className="trends__items-row">
              <label className="trends__label">Age Range</label>
            </div>
            <div className="trends__items-row">
              <label className="trends__items-label">{ageRange}</label>
            </div>
          </div>

          <div className="trends__content-item trends__content-item--reverse">
            <div className="trends__items-row">
              <label className="trends__label">Post</label>
            </div>
            <div className="trends__items-row">
              <label className="trends__items-label--normal">{post}</label>
            </div>
          </div>
          <div className="trends__content-item">
            <div className="trends__items-row">
              <label className="trends__label">URL Post</label>
            </div>
            <div className="trends__items-row">
              <a
                href={urlPost.replace("blob:", "")}
                target="_blank"
                className="trends__items-link"
              >
                Click here to vist the Post
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
