import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/InnerPage.scss";

const LinkedInRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [authCode, setAuthCode] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const error = params.get("error");

    if (code) {
      setAuthCode(code);
      navigate(`/linkedin-callback`, {
        state: {
          code,
        },
      });
    } else if (error) {
      console.error("LinkedIn auth error:", error);
    }
  }, [location]);

  return (
    <div className="content-column inner-page">
      <div className="content">
        {authCode ? (
          <div className="linkedin-post__wrapper">
            <h2>Authorization Code</h2>
            <p>{authCode}</p>
            <p>Use this code to manually exchange for an access token.</p>
          </div>
        ) : (
          <div className="linkedin-post__wrapper">
            <p>Redirecting...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkedInRedirect;
