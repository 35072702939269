// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageAnalysisWrapper {
  padding: 4px 4px 0 4px;
}
.imageAnalysisContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 24px;
  flex-wrap: wrap;
}
.imageAnalysisContentColumn {
  display: flex;
  gap: 8px;
  flex-direction: row;
  flex: 1 0 200px;
}
.imageAnalysisContentColumn__Title {
  font-weight: 800;
}
.imageAnalysisContentRow {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  text-transform: capitalize;
}
.imageAnalysisContentRow:last-child {
  font-weight: 800;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/style/ImageAnalysis.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;EACA,eAAA;AAAJ;AAEE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAIE;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,0BAAA;AAFJ;AAII;EACE,gBAAA;EACA,iBAAA;AAFN","sourcesContent":[".imageAnalysis {\n  &Wrapper {\n    padding: 4px 4px 0 4px;\n  }\n  &Content {\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n    gap: 24px;\n    flex-wrap: wrap;\n  }\n  &ContentColumn {\n    display: flex;\n    gap: 8px;\n    flex-direction: row;\n    flex: 1 0 200px;\n\n    &__Title {\n      font-weight: 800;\n    }\n  }\n\n  &ContentRow {\n    flex-grow: 1;\n    flex-basis: 0;\n    min-width: 0;\n    text-transform: capitalize;\n\n    &:last-child {\n      font-weight: 800;\n      text-align: right;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
