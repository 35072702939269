import React, { useEffect, useState } from "react";
import { getTrends } from "../../utils/getTrends";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { TrendsDetails } from "../TrendsDetails";
import TextLoader from "../Loaders/TextLoader";
import TextCount from "./../TextCount/index";

const Trends = () => {
  const minInputString = 3;
  const maxInputString = 100;
  const [trendKeywords, setTrendKeywords] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasDataResponse, setHasDataResponse] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableClear, setDisableClear] = useState(true);

  // Trends States
  const [showTrends, setShowTrends] = useState(false);
  const [trendsResponse, setTrendsResponse] = useState([]);
  const [hasTrendsResponse, setHasTrendsResponse] = useState(false);

  // Fetch Trends
  const fetchTrends = async () => {
    setShowTrends(true);
    try {
      const responseData = await getTrends(
        {
          input_content: trendKeywords,
        },
        process.env.REACT_APP_TRENDS_FUNC_URI
      );
      setTrendsResponse(responseData);
      setHasTrendsResponse(true);
      setIsLoading(false);
    } catch (error) {
      setHasTrendsResponse(false);
    }
  };

  const handleTextChange = (event) => {
    setDisableClear(false);
    setTrendKeywords(event.target.value);
  };

  /**
   * Process the Trend Analysis
   */
  const processTrendAnalysis = () => {
    setIsLoading(true);
    fetchTrends();
  };

  const clearForm = () => {
    setTrendKeywords("");
    setDisableInput(false);
    setDisableSubmit(true);
    setDisableClear(true);
  };

  const redirectToHome = () => {
    setIsLoading(false);
    setHasDataResponse(false);
    setShowTrends(false);
    setHasTrendsResponse(false);
    setTrendsResponse(null);
    setTrendKeywords("");
  };

  useEffect(() => {
    if (hasTrendsResponse) {
      setHasDataResponse(true);
    }
    if (
      trendKeywords.length >= minInputString &&
      trendKeywords.length <= maxInputString
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [
    trendKeywords,
    disableSubmit,
    hasDataResponse,
    hasTrendsResponse,
    showTrends,
  ]);

  return (
    <div className="free-analysis__wrapper">
      <div className="free-analysis__content">
        {!isLoading && !hasDataResponse && (
          <>
            <p>
              Next, enter a short phrase or keyword, and we’ll find the top 10
              trends on LinkedIn along with additional information to
              supercharge your post idea.
            </p>
            <div className="free-analysis__content-area">
              <textarea
                onChange={handleTextChange}
                disabled={disableInput}
                value={trendKeywords}
              />
              <TextCount
                current={trendKeywords.length}
                max={maxInputString}
                limit={maxInputString}
                min={minInputString}
              />
            </div>
            <div className="free-analysis__action-area">
              <button
                className="free-analysis__clear-button"
                onClick={clearForm}
                disabled={disableClear}
              >
                Clear
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  className="app-icon-space"
                />
              </button>
              <button
                className="free-analysis__button"
                disabled={disableSubmit}
                onClick={processTrendAnalysis}
              >
                Continue
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  className="app-icon-space"
                />
              </button>
            </div>
          </>
        )}

        {hasDataResponse && hasTrendsResponse && showTrends && (
          <TrendsDetails
            result={trendsResponse}
            trendKeywords={trendKeywords}
          />
        )}

        {isLoading && !hasTrendsResponse && <TextLoader count={1} />}

        {hasDataResponse && (
          <div className="linkedin-post__button-wrapper linkedin-post__button-wrapper--align-right linkedin-post__button-wrapper--has-margin">
            <button className="linkedin-post__button" onClick={redirectToHome}>
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="app-icon-space"
              />
              Start Over
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Trends;
