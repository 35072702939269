import React from "react";
import "../style/Preloader.scss";
export const Preloader = (props) => {
  const status = props.status;
  const position = props.position;
  if (status)
    return (
      <div
        id="digi-loader"
        className={`removed-on-load ${
          position === "fixed" ? "fixed" : "relative"
        }`}
      >
        <div className="main-loader">
          <span className="loader1"></span>
          <span className="loader2"></span>
          <span className="loader3"></span>
        </div>
      </div>
    );
};
