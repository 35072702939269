// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-heat-map__wrapper {
  display: flex;
  flex-direction: row;
}
.image-heat-map__image img {
  max-width: 100%;
  border: 1px solid #d0d1d3;
  padding: 4px;
}
.image-heat-map__reference {
  max-width: 100%;
  padding: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/style/ImageHeatMap.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,mBAAA;AAAJ;AAGI;EACE,eAAA;EACA,yBAAA;EACA,YAAA;AADN;AAKE;EACE,eAAA;EACA,cAAA;AAHJ","sourcesContent":["@import \"./variables\";\n\n.image-heat-map {\n  &__wrapper {\n    display: flex;\n    flex-direction: row;\n  }\n  &__image {\n    img {\n      max-width: 100%;\n      border: 1px solid #d0d1d3;\n      padding: 4px;\n    }\n  }\n\n  &__reference {\n    max-width: 100%;\n    padding: $gap_small 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
