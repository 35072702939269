import React from "react";
import "../../style/LinkedInPostTextAnalysisLoader.scss";

const LinkedInPostImageAnalysisLoader = () => {
  return (
    <div className="imageAnalysisWrapper">
      <div className="imageAnalysisContent">
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
        <div className="imageAnalysisContentColumn">
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
          <div className="imageAnalysisContentRow">
            <div className="text-placeholder"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInPostImageAnalysisLoader;
