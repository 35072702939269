// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  padding: 0;
  margin-bottom: 12px;
}
.loader-wrapper__content {
  gap: 12px;
}

.loader-title {
  flex: 1 1;
  border-bottom: 0;
}

.loader-row {
  display: flex;
  flex: 1 1;
}

.text-placeholder {
  width: 100%; /* Placeholder size */
  height: 24px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/style/LinkedInPostTextAnalysisLoader.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,mBAAA;AACF;AAAE;EACE,SAAA;AAEJ;;AACA;EACE,SAAA;EACA,gBAAA;AAEF;;AACA;EACE,aAAA;EACA,SAAA;AAEF;;AAAA;EACE,WAAA,EAAA,qBAAA;EACA,YAAA;EACA,yEAAA;EACA,0BAAA;EACA,gCAAA;AAGF;;AAAA;EACE;IACE,2BAAA;EAGF;EADA;IACE,4BAAA;EAGF;AACF","sourcesContent":[".loader-wrapper {\n  padding: 0;\n  margin-bottom: 12px;\n  &__content {\n    gap: 12px;\n  }\n}\n.loader-title {\n  flex: 1;\n  border-bottom: 0;\n}\n\n.loader-row {\n  display: flex;\n  flex: 1;\n}\n.text-placeholder {\n  width: 100%; /* Placeholder size */\n  height: 24px;\n  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);\n  background-size: 200% 100%;\n  animation: loading 1.5s infinite;\n}\n\n@keyframes loading {\n  0% {\n    background-position: 200% 0;\n  }\n  100% {\n    background-position: -200% 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
