import React from "react";
import "../style/Trends.scss";
import Accordion from "./AccordionComponent/Accordion";

export const TrendsDetails = (props) => {
  const trends = props.result.topTrends;
  const keywords = props.trendKeywords;
  return (
    <div className="trends__wrapper">
      <div className="trends__keywords-container">
        Trends for <div className="trends__keywords">{keywords}</div>
      </div>
      <div className="trends__content-wrapper">
        <Accordion data={trends} />
      </div>
    </div>
  );
};
