import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/InnerPage.scss";

const LinkedInAuth = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const code = state?.code;
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_LINKEDIN_SERVER_APP_ACCESS_TOKEN_URI,
        headers: {
          "x-functions-key":
            process.env.REACT_APP_LINKEDIN_APP_ACCESS_TOKEN_FUNCTION_KEY,
          "Content-Type": "application/json",
        },
        data: {
          code,
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response) {
            navigate(`/linkedin-post-analysis`, {
              state: {
                token: response.data.access_token,
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching access token:", error);
        });
    };

    if (code && !hasFetched) {
      getAccessToken();
      setHasFetched(true);
    }
  }, [code, hasFetched, navigate]);

  return (
    <div className="content-column inner-page">
      <div className="content">
        <h1>LinkedIn Authentication</h1>
        <p>Authenticating the user</p>
      </div>
    </div>
  );
};

export default LinkedInAuth;
