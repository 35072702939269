// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-page {
  padding: 24px;
}
@media screen and (max-width: 680px) {
  .inner-page {
    padding: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/style/InnerPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EAFF;IAGI,aAAA;EAGF;AACF","sourcesContent":[".inner-page {\n  padding: 24px;\n  @media screen and (max-width: 680px) {\n    padding: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
